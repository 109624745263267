.app {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.app iframe {
    width: 100%;
    height: 100%;
}

.spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}