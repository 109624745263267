.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: transparent;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.inner {
    position: relative;
    z-index: 1;
    width: calc(100% - 40px);
    max-width: 720px;
    height: auto;
    max-height: calc(100% - 40px);
    background-color: #000;
    color: #fff;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 1.14rem;
    font-weight: 600;
    letter-spacing: 0.12em;
    border-bottom: 1px solid var(--color-point);
    width: 100%;
    text-align: center;
    padding: 0.5em 1em;
}

.contents {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
}

.isPc {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 46px;  
}

.isPcRow {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    gap: 40px;
    width: 100%;
    padding: 0 20px;
    justify-content: space-between;
}

.textWrap {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 0 0 40px;
    border-left: 1px solid var(--color-point);
    font-size: 0.875rem;
    text-align: center;
    min-width: 210px;
}

.iconWrap {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex: auto;
}

.icon {
    fill: var(--color-point);
}

.iconPcSmall {
    width: auto;
    height: 64px;
}
.iconPc {
    width: auto;
    height: 72px;
}


.button {
    width: 100%;
    height: 54px;
    background-color: var(--color-point);
    max-width: 320px;
    margin-top: 40px;
    font-weight: 600;
    letter-spacing: 0.2em;
    font-size: 1.12rem;
    color: #000;
}

@media screen and (max-width: 850px) {
    .isPc {
        flex-flow: row nowrap;
        align-items: flex-start;
        gap: 10px;
    }

    .isPcRow {
        padding: 0;
    }
    .textWrap {
        border-left: none;
        border-top: 1px solid var(--color-point);
        width: 100%;
        justify-content: center;
        padding: 0;
        min-width: unset;
    }
}

@media screen and (orientation: landscape) and (max-height: 400px) {
    .contents {
        flex-flow: row nowrap;
    }

    .button {
        width: auto;
        margin-left: 40px;
        padding: 0 20px;
    }
}