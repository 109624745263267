@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500&family=Roboto+Mono&display=swap');

:root {
  --color-point: #F0FF3C;
}

* {
  box-sizing: border-box;
}

html {
  touch-action: pan-x pan-y;
  background-color: black;
}

body {
  overflow: hidden;
  font-family: 'Roboto Mono', 'Noto Sans JP', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

iframe {
  border: none !important;
}

p {
  font-size: 1em;
  line-height: 1.75em;
}

p big {
  display: block;
  margin-bottom: 0.5em;
}

button, a{
  border: 0;
  outline: none;
  box-shadow: none;
  color: inherit;
}